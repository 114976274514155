<template>
  <GuestLayout>
    <div class="w-full overflow-hidden">
      <Banner />
      <FeatureNews />
      <QuestionsAndAnswers />
      <PolicyAndProcedure :mappings="mappings.data" />
      <Procedure />
      <Publication />
      <ContactForm />
    </div>
  </GuestLayout>
</template>
<script>
import GuestLayout from '@/Layouts/GuestLayout.vue'
import Banner from '@/Pages/User/Home/Layouts/Banner.vue'
import FeatureNews from '@/Pages/User/Home/Layouts/FeatureNews.vue'
import QuestionsAndAnswers from '@/Pages/User/Home/Layouts/QuestionsAndAnswers.vue'
import PolicyAndProcedure from '@/Pages/User/Home/Layouts/PolicyAndProcedure.vue'
import Procedure from '@/Pages/User/Home/Layouts/Procedure.vue'
import Publication from '@/Pages/User/Home/Layouts/Publication.vue'
import ContactForm from '@/Pages/User/Home/Layouts/ContactForm.vue'

export default {
  components: {
    GuestLayout,
    Banner,
    FeatureNews,
    QuestionsAndAnswers,
    PolicyAndProcedure,
    Procedure,
    Publication,
    ContactForm
  },
  props: {
    mappings: {type: Object, default: () => ({})},
  }
}
</script>
